import { postCode, postVerify } from "../../common/services/mfa";

const useMultifactor = () => {
    const secondsWaiting = 120;

    const storeData = (key, value) => {
        try {
            const jsonValue = JSON.stringify(value);

            localStorage.setItem(key, jsonValue);
        } catch (error) {
            throw new Error(error);
        }
    };

    const getData = (key) => {
        try {
            const value = localStorage.getItem(key);

            return value !== null ? JSON.parse(value) : null;
        } catch (error) {
            return null;
        }
    };

    const deleteData = (key) => {
        try {
            localStorage.removeItem(key);
            const value = localStorage.getItem(key);

            return value === null;
        } catch (error) {
            return false;
        }
    };

    const verifySendCodeDateTime = (sendCodeDateTime, value, secondsToValidate, reSend)  => {
        const oldDateTime = getData('sendCodeDateTime');
    
        let resp = {
            data: value,
            timer: new Date((secondsToValidate - 1)* 1000).toISOString().substr(14, 5),
            valid: false
        };
    
        if(oldDateTime && oldDateTime.value === value && reSend === false){
            let diff = sendCodeDateTime - oldDateTime.datetime;
            let seconds = diff/(1000);
    
            if (seconds < secondsToValidate) {
                resp.timer = new Date((secondsToValidate - seconds)* 1000).toISOString().substr(14, 5);
                resp.valid = (resp.timer  === "00:00") ? true  : false;
    
                return resp;
            }else{
                resp.timer = "00:00";
                resp.valid = true;
    
                storeData('sendCodeDateTime', {});
                return resp;
            }
        }
            
        let obj = {
            value: value,
            datetime: sendCodeDateTime 
        };
        
        storeData('sendCodeDateTime', obj);
        return resp;   
    };

    const updateCounter = (resend, setTime, setResend) => {
        const timer = setTimeout(async () => {
            if (!resend) {
                let data = getData('mfaInfo');
                let resp = verifySendCodeDateTime(new Date().getTime(), data.attributes.email, secondsWaiting, false);

                setResend(resp.valid)
                setTime(resp.timer)
            }
        }, secondsWaiting * 10);

        return () => clearInterval(timer);
    };

    const sendCode = async () => {
        try {
            const data = getData('mfaInfo');
            const timestamp = new Date().getTime();
            const verifyCode = verifySendCodeDateTime(timestamp, data.attributes.email, secondsWaiting, false);
    
            const shouldSendCode = 
                verifyCode.data !== data.attributes.email || 
                verifyCode.timer === "01:59" || 
                verifyCode.valid === true;
    
            if (shouldSendCode) {
                const resultPostCode = await postCode();
                return {
                    exitoso: resultPostCode.exitoso,
                    data: resultPostCode.data
                };
            }
    
            return {
                exitoso: false,
                data: "Por seguridad, debes esperar 2 minutos antes de hacerlo de nuevo."
            };
    
        } catch (error) {
            const msgError = error?.response?.data?.data?.descripcion || "Ha ocurrido un error inesperado con el envío del código. Intenta de nuevo.";
            return { exitoso: false, data: msgError };
        }
    };

    const resendCode = async (resend, setResend) => {
        if (resend) {
            const resp = await postCode();

            if (resp.exitoso) {
                const data = getData('mfaInfo');
                verifySendCodeDateTime(new Date().getTime(), data.attributes.email, secondsWaiting, true);

                setResend(false);

                return { exitoso: true }
            } else {
                return resp;
            }
        } else {

            return { exitoso: false };
        }
        
    };

    const verifyCode = async (code) => {
        try {
            const resultPostVerify = await postVerify(code);
            return resultPostVerify;
        } catch (error) {
            let msgError = error?.response?.data?.data?.descripcion ? error.response.data.data.descripcion : "Ha ocurrido un error inesperado con la validación del código. Intenta de nuevo.";
            return { exitoso: false, data: msgError }
        }
    };

    const formatEmail = (email) => {
        return email.replace(/^(.)(.*)(.@.*)$/, (_, first, middle, last) => 
            first + '*'.repeat(middle.length) + last
        );
    };

    return {
        sendCode,
        verifyCode,
        updateCounter,
        resendCode,
        getData,
        deleteData,
        formatEmail
    };
};

export default useMultifactor;