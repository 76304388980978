import React, { useState, useEffect, useContext } from "react";
import logo from "../../images/logo-masorden-gris-small.svg";
import loginImage from "../../images/img-login.svg";
import LoginLayout from "../../layouts/login-layout";
import loginStyles from "../../common/styles/Login";
import { GTIWebTextField, GTIWebButton, Paper, Grid, CircularProgress, ArrowBack, Link, Error, CheckCircle, GTIWebAlert, ErrorOutline } from "@gti-controls/web";
import { useHistory, useLocation } from "react-router-dom";
import useMultifactor from "../../hooks/useMultifactor";
import { AuthContext } from "../../providers/auth";
import { saveAuthInfo } from "../../common/services/auth-service";

const MFA = () => {
    const { dispatch } = useContext(AuthContext);
    const history = useHistory();
    const location = useLocation();
    const classes = loginStyles();
    const { updateCounter, resendCode, verifyCode, getData, deleteData, formatEmail } = useMultifactor();
    const [pageTexts, setPageTexts] = useState({
        title: "Revisa tu Correo",
        instruction: "",
        email: ""
    });
    const [formData, setFormData] = useState({
        code: "",
        codeState: null,
        codeLegend: null,
        codeIcon: null,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [time, setTime] = useState("");
    const [resend, setResend] = useState(false);
    const [alert, setAlert] = useState({ type: null, message: null });
    const [attempts, setAttempts] = useState(0);

    useEffect(() => {
		updateCounter(resend, setTime, setResend);
		// eslint-disable-next-line
	}, [time, resend]);

    useEffect(() => {
        setPageTexts({
            title: "Revisa tu Correo",
            instruction: "Acabamos de enviarte un código para poder iniciar sesión a tu correo electrónico:",
            email: location?.state?.response?.user?.email
        })
    // eslint-disable-next-line 
    }, [location]);

    const handleChange = (e) => {
        const value = e.target.value.trim();
        if (!(/\D/g.test(value))) setFormData({ ...formData, [e.target.name]: e.target.value.trim() });
    };

    const updateFormData = (fieldName, isValid, errorMessage = null) => {
        const state = isValid ? "success" : "error";
        const icon = isValid 
            ? <CheckCircle style={{ color: "#108043" }} />
            : <Error style={{ color: "#b71c1c" }} />;
        const legend = isValid ? null : errorMessage;
    
        setFormData(prevFormData => ({
            ...prevFormData,
            [`${fieldName}State`]: state,
            [`${fieldName}Legend`]: legend,
            [`${fieldName}Icon`]: icon,
        }));
    
        return isValid;  // Devuelve el resultado de la validación
    };
    
    const validateForm = () => {
        const isValid = formData.code !== "" && formData.code !== null;
        return updateFormData("code", isValid, "Introduce el código, es un campo obligatorio");
    };
    
    const validateInput = () => {
        const regex = /^\d{6}$/;
        const isValid = regex.test(formData.code);
        return updateFormData("code", isValid, "El código debe tener 6 dígitos.");
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
        
            if (validateForm()) {
                const result = await verifyCode(formData.code);

                if (result.exitoso) {
                    const tokensInfo = getData('mfaInfo');
                    const loginInfo = getData('mfaInfoAuth');

                    saveAuthInfo({ cognito: tokensInfo });
                    dispatch(loginInfo);

                    const accountsLength = loginInfo.payload.accountsLength

                    history.replace(accountsLength === 1 ? { pathname: '/' } : {
                        pathname: '/SeleccionarCuenta',
                        state: {
                            verb: {
                                ItemID: -1,
                                VerboClassName: 'SeleccionarCuenta',
                                Texto: 'Seleccionar Cuenta'
                            },
                        }
                    });

                    deleteData('mfaInfo');
                    deleteData('mfaInfoAuth');
                } else {
                    let newAttemps = attempts + 1;
                    setAttempts(newAttemps);
                    newAttemps < 3 ? setAlert({ type: "error", message: result.data }) : redirectLogin();
                }
            }
        } catch (error) {
            setAlert({ type: "error", message: "Parece que +ORDEN está tardando en responder, inténtalo más tarde." });
        }
    };

    const redirectLogin = () => {
        history.replace({
            pathname: '/Login'
        });
    };

    const handlerResendCode = async() => {
        setIsLoading(true);
        const resultResend = await resendCode(resend, setResend);

        setAlert(resultResend.exitoso ? { type: "success", message: "Se ha enviado el código correctamente" } : { type: "warning", message: "Espera un momento, tu código va en camino, inténtalo de nuevo en 2 minutos." });
        setIsLoading(false);
    };

    const handleOnClose = () => {
        setAlert({ type: null, message: null });
    };

    return (
        <LoginLayout image={loginImage} title={"Configura empresas, ajustes y mucho más."}>
            <Grid item component={Paper} className={classes.loginContainer}>
                <div className={classes.logo}>
                    <img src={logo} alt="Logo" className={classes.imageIcon} />
                </div>

                <Grid container className={classes.containerTitle}>
                    <div className={classes.backSpace}>
                        <GTIWebButton
                            icon={<ArrowBack className={classes.backButton} />}
                            iconPosition="start"
                            onlyIcon={true}
                            onClick={redirectLogin}
                        />
                    </div>
                    <div className={classes.title}>
                        {pageTexts.title}
                    </div>
                </Grid>

                <div className={classes.paper}>
                    <form className={classes.form} onSubmit={handleSubmit}>
                        <Grid item xs={12}>
                            { alert.type === "error" && <GTIWebAlert severity={"error"} alertText={alert.message} /> }
                        </Grid>
                        <p className={classes.instruction}>{pageTexts.instruction}</p>
                        <p className={classes.sendMail}>{formatEmail(pageTexts.email)}</p>
                        <div className={classes.contentCounter}><span className={classes.time}>{time}</span></div>

                        <GTIWebTextField
                            name="code"
                            type="text"
                            domain={'codigo'}
                            value={formData.code}
                            labelText={"Introduce el código"}
                            legendText={formData.codeLegend}
                            width="100%"
                            onChange={handleChange}
                            state={formData.codeState}
                            icon={formData.codeIcon}
                            maxLength={6}
                            required
                            onBlur={validateInput}
                        />

                        {
                            alert.type && alert.type !== "error" ? (
                                <Grid className={classes.sendCode}>
                                    <GTIWebAlert 
                                        severity={alert.type} 
                                        alertText={alert.message} 
                                        icon={<ErrorOutline />} 
                                        onClose={handleOnClose}
                                    />
                                </Grid>
                            ) : null
                        }
                        
                        <Grid className={classes.sendCode}>
                            <Link className={classes.retrySend} onClick={handlerResendCode}>Enviar el código nuevamente</Link>
                        </Grid>

                        <Grid container justify="center" className={classes.buttonSpace}>
                            <GTIWebButton
                                onlyIcon={isLoading}
                                icon={isLoading ? <CircularProgress size={30} /> : null}
                                disabled={isLoading}
                                iconPosition={"end"}
                                type={"submit"}
                                buttonText={"Continuar"}
                            />
                        </Grid>
                    </form>
                </div>
            </Grid>
        </LoginLayout>
    );
};

export default MFA;