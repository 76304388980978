import { Lambda } from "../http-service";

export const postCode = async () => {
    return await Lambda({ resource: `mfa/codigo-admin`, method: "POST", isAdmin: true });
};

export const postVerify = async (code) => {
    let payload = {
        codigo: code
    };

    return await Lambda({ resource: `mfa/codigo-admin/verifica`, method: "POST", data: payload, isAdmin: true });
};