import { useCallback } from "react";
import moment from 'moment';
import 'moment/locale/es';
import { Brightness1 } from "@gti-controls/web";

/** Hook formateador de fechas o strings */
const useFormat = () => {

    // eslint-disable-next-line
    const formatDate = useCallback((dateText, format) => {
        moment.locale('es');
        return moment(dateText).utcOffset(0).format(format).replace(/\b\w{3}\b/g, match => match.charAt(0).toUpperCase() + match.slice(1));;
    });
    
    /** Formatea el valor de texto de un row,
     * si este contiene el tipo de error 1 entonces se le agrega
     * un asterisco rojo
     */
     const formatValue = (row) => {
        if(row && row.type && row.type === 1 && isNaN(row.text))
            return (<>{row.text}<span style={{ color: '#b71c1c' }}>*</span></>);
        else 
            return row.text;
    };

    const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const dateFormatter = (params) => {
        // eslint-disable-next-line
        return (params.value) ? params.value === "1900-01-01T00:00:00.000Z" ? "" : formatDate(params.value, 'DD/MMM/YYYY').replace('\.\/','/') : '-';
    };

    const dateTimeFormatter = (params) => {
        // eslint-disable-next-line
        return (params.value) && params.value !== "1900-01-01T00:00:00.000Z" ? formatDate(params.value, 'DD/MMM/YYYY h:mm a').replace('\.\/','/') : '-'; 
    };

    const dateSolicitudesFormatter = (params) => {
        const options = {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        };
        let date = new Date(params).toLocaleTimeString('es-MX', options);
        const parts = date.replace(/\.$/, '').replace(',', '').split(' ');
        date = `${parts[0]}/${parts[1][0].toUpperCase() + parts[1].slice(1)}/${parts[2]} ${parts[3]} ${parts[4]}`;

        return date;
        
    }

    const emptyNumberFormatter = (params) => {
        return (params.value) ? params.value : '-';    
    };

    const emptyCurrencyFormatter = (params) => {
        return (params.value) ? currencyFormatter.format(Number(params.value)) : '-';    
    };

    const activeFormatter = (params) => {
        return (<Brightness1 style={{ color: params.formattedValue === true || params.formattedValue === 'true' || params.formattedValue === 1 ? "#108043" : "#d32f2f" }} />);
    };

    const decimalFormatter = (params) => {
        return (params.value) ? parseFloat(params.value).toFixed(2) : '-';
    };

    const percentFormatter = (params) => {
        return (params.value) ? `${parseFloat(params.value).toFixed(2)} %` : '-';
    };

    /** Si el row contiene total se regresan sus datos
     * en negritas, de lo contrario se regresa el numero o se 
     * formatea su valor para determinar si es un error grave (en Encuestas)
     */
    const totalFormatter = (params) => {
        let value = params.formattedValue.toString().includes(',')
        return ( params.row?.text?.includes('Total') ? <strong>{params.formattedValue}</strong>  :  !isNaN(value) ? params.formatValue : formatValue(params.row) 
        );
    };

    const questionType = (type) => {
        const types = {
            opcionesMultiples: "Múltiples Opciones - Una respuesta",
            abierta: "Texto Libre"
        }
        switch (type.value) {
            case types.opcionesMultiples:
                return "Opción múltiple";
            case types.abierta:
                return "Abierta";
            default:
                return " - ";
        }
    }

    const statusDesc = (type) => {
        const desc = {
            visto: 'Visto',
            pendiente: 'Pendiente'
        };
        
        switch (type.value) {
            case desc.visto:
                return 'Si'
            case desc.pendiente:
                return 'No'
            default:
                return ' - '
        }
    }

    const emptyValue = (param) => {
        return param.value !== '' ? param.value : '-';
    }

    const toLocalISOString = (date) => {
        // Obtén componentes de la fecha y hora local
        let year = date.getFullYear();
        let month = String(date.getMonth() + 1).padStart(2, '0'); // Meses comienzan en 0
        let day = String(date.getDate()).padStart(2, '0');
        let hours = String(date.getHours()).padStart(2, '0');
        let minutes = String(date.getMinutes()).padStart(2, '0');
        let seconds = String(date.getSeconds()).padStart(2, '0');
        let mili = String(date.getMilliseconds()).padStart(3, '0');
        // Construye la cadena en formato ISO 8601 local
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${mili}Z`;
    };

    /** Retornara el texto formateado */
    return {
        dateFormatter,
        dateTimeFormatter,
        emptyNumberFormatter,
        emptyCurrencyFormatter,
        activeFormatter,
        decimalFormatter,
        percentFormatter,
        totalFormatter,
        questionType,
        statusDesc,
        emptyValue,
        dateSolicitudesFormatter,
        toLocalISOString
    };
};

export default useFormat;