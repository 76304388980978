import { createContext, useEffect, useReducer } from 'react';
import { getSessionHandler, logout } from "../../common/services/auth-service";
import config from "../../config";

/** Constante del contexto de Autorizacion */
export const AuthContext = createContext({});

/** Estado inicial del contexto */
const initialState = {
	isAuthenticated: Boolean(localStorage.length),
	user: {},
	accountsLength: Number(localStorage.getItem("accountsLength")),
	firstAccount: null
};

const signOff = async () => {
	await fetch(`${config.ADMIN_API_URL}sesiones/`, {
		method: 'DELETE',
		headers: { "x-cognito-access-token": `${(localStorage.length > 0) ? (await getSessionHandler()).AccessToken : null}` }
	}).then(() => {
		throw new Error('Notificación de cierre de sesión');
	})
		.catch(() => {
			logout();
		});
};

/** Reducer que se encarga de las acciones de autorizacion */
const reducer = (state, action) => {
	switch (action.type) {
		case "LOGIN":
			localStorage.setItem("user", JSON.stringify(action.payload.user));
			localStorage.setItem("accountsLength", action.payload.accountsLength);
			return {
				...state,
				isAuthenticated: true,
				user: action.payload.user,
				accountsLength: action.payload.accountsLength,
				firstAccount: action.payload.firstAccount,
			};
		case "LOGOUT":
			if (action.signOff) {
				signOff();
			}
			return {
				...state,
				isAuthenticated: false,
				user: null,
				accountsLength: 0,
				firstAccount: null
			};
		default:
			return state;
	}
};

export const AuthProvider = (props) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	/** Cuando se carga el contenedor se verifica si el token esta activo */
	useEffect(() => {
		try {
			const user = JSON.parse(localStorage.getItem("user"));
			if (user && Object.keys(user).length > 0) {
				dispatch({
					type: "LOGIN",
					payload: {
						user: JSON.parse(localStorage.getItem("user") || '{}'),
						accountsLength: Number(localStorage.getItem("accountsLength")),
						firstAccount: null
					},
				});
			} else {
				throw new Error("LOGOUT");
			}
		}
		catch (error) {
			dispatch({
				type: "LOGOUT",
			});
		}
	}, []);

	return (
		<AuthContext.Provider value={{
			state,
			dispatch
		}}>
			{props.children}
		</AuthContext.Provider>
	);
};