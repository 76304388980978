/** Modulos necesarios del contenedor */
import React, { useContext, useEffect, useState } from "react";
import logo from "../../images/logo-masorden-gris-small.svg";
import loginImage from "../../images/img-login.svg";
import LoginLayout from "../../layouts/login-layout";
import loginStyles from "../../common/styles/Login";
import { GTIWebTextField, GTIWebButton, GTIWebLink, Paper, Grid, Error, CheckCircle, CircularProgress } from "@gti-controls/web";
import useHttpLogin from "../../hooks/useHttpLogin";
import { useHistory, useLocation } from "react-router-dom";
import config from "../../config";
import { AuthContext } from "../../providers/auth";
import { useInputValidatorEmails } from "../../hooks/useInputValidator";
import LoginAlert from "../../components/login-alert";
import useMultifactor from "../../hooks/useMultifactor";
const loginTypes = {
	NORMAL: 1,
	SSO: 2
};
const singleAccount = 1;
const authType = {
	SIMPLE: 0,
	MFA: 1
};

/** Constante del contenedor */
export const Login = () => {

	/** Hooks de uso */
	const history = useHistory();
	const location = useLocation();
	const { dispatch } = useContext(AuthContext);
	const classes = loginStyles();
	/** Usuario */
	const [formData, setFormData] = useState({
		username: "",
		password: "",
		userState: null,
		enterState: null,
		userLegend: "Entra con el dato que tienes registrado",
		userIcon: null,
		enterLegend: null
	});
	/** Contraseña */
	const { error, isLoading, sendRequest: loginRequest, setError, validateSSO, loginSSO, codeSSO, saveAuthInfo, saveMFAToken } = useHttpLogin();
	const { validateInputEmail } = useInputValidatorEmails();
	const { sendCode } = useMultifactor();
	const [loginType, setLoginType] = useState(loginTypes.NORMAL);
	const [isLoadingMFA, setIsLoadingMFA] = useState(false);

	useEffect(() => {
		if (error) {
			if (error.error === "11")
				setFormData({ ...formData, userState: "error", userIcon: <Error style={{ color: "#b71c1c" }} /> })
			if (error.error === "2")
				setFormData({ ...formData, userState: "success", enterState: "error" })
		}
		else {
			setFormData({ ...formData, userState: null, userIcon: null, enterState: null })
		}
		// eslint-disable-next-line 
	}, [error]);

	useEffect(() => {
		if (location.search) {
			let code = location.search.substr(1).split('=')[1];
			handleCode(code);
		}
		// eslint-disable-next-line
	}, [])

	const handleCode = async (code) => {
		const tokens = await codeSSO(code);
		initSSO(tokens);
	};

	const initSSO = async (tokens) => {
		let resp = await loginSSO(tokens);
		handleResponse(resp);
	};

	/** Redirecciona al usuario de acuerdo a las cuentas que tiene disponibles para seleccionar */
	const redirectAfterLogin = (accountsLength) => {
		history.replace(accountsLength === 1 ? { pathname: '/' } : {
			pathname: '/SeleccionarCuenta',
			state: {
				verb: {
					ItemID: -1,
					VerboClassName: 'SeleccionarCuenta',
					Texto: 'Seleccionar Cuenta'
				},
			}
		});
	};

	const redirectForgot = () => {
		history.replace({
			pathname: '/Recuperacion'
		});
	};

	const handleSubmit = async (e) => {

		if (loginType === loginTypes.NORMAL) {
			await accessConsole(e);
		} else {
			await accessConsoleSSO(e);
		}
	};

	/** Funcion que logea el usuario a la consola */
	const accessConsole = async (e) => {
		e.preventDefault();
		/** Se valida la forma */
		if (validateForm()) {
			/** Invocacion del hook sendRequest como loginRequest */
			loginRequest({
				url: config.API_URL,
				username: formData.username,
				password: formData.password,
			}, handleResponse);
		}
	};

	/** Constante que almacena la informacion de usuario en sesion
	 * en caso de que la respuesta haya sido exitosa
	*/
	const handleResponse = async (response) => {
		if (loginType === loginTypes.NORMAL && response.despachoTipoLogin === authType.MFA) {
			setIsLoadingMFA(true);
			saveMFAToken(response);
			let resultCode = await sendCode();
			

			if (resultCode?.exitoso) {
				history.push({
					pathname: '/MFA',
					state: {
						response: response
					}
				});
			} else {
				let errObj = {
					error: resultCode.data,
					error_description: resultCode.data
				};

				setError(errObj);
			}
			setIsLoadingMFA(false);
		} else {
			saveAuthInfo(response);
			dispatch({
				type: "LOGIN",
				payload: {
					user: {
						username: response.user.name,
						userId: response.user.userId,
						sesionId: response.user.sessionId,
						userLogin: response.user.userLogin,
						sesionIdWF: response?.SessionWF && response?.SessionWF?.sesionIdWF ? response?.SessionWF?.sesionIdWF : 0,
						emailTestWFN: response?.user?.usuarioEmailTestWFN || null
					},
					accountsLength: response.accounts.length,
					firstAccount: (response.accounts.length === singleAccount) ? {
						despachoId: response.accounts[0].DespachoID,
						nombre: response.accounts[0].DespachoNombre,
						contacto: response.accounts[0].DespachoContacto,
						escuelaNombre: response.accounts[0].EscuelaNombre,
						empresas: response.accounts[0].Empresas,
					} : null,
				},
			});
			redirectAfterLogin(response.accounts.length);
		}
	};

	const accessConsoleSSO = async (e) => {
		e.preventDefault();
		if (validateFormSSO()) {
			let resp = await validateSSO(formData.username);
			if (resp && resp.LinkSSO) {
				window.location.replace(resp.LinkSSO);
			}
		}
	};

	const validateForm = () => {
		let fromInfo = formData;

		fromInfo = {
			...fromInfo,
			userLegend: formData.username === "" ? "Correo electrónico, Teléfono celular o CURP es un campo obligatorio" : formData.userLegend,
			userState: formData.username === "" ? "error" : formData.userState,
			userIcon: formData.username === "" ? <Error style={{ color: "#b71c1c" }} /> : formData.userIcon,
			enterLegend: formData.password === "" ? "Contraseña es un campo obligatorio" : formData.enterLegend,
			enterState: formData.password === "" ? "error" : formData.enterState,
		};
		setFormData(fromInfo);
		return (fromInfo.userState === "error" || fromInfo.enterState === "error") ? false : true;
	};

	const validateFormSSO = () => {
		let fromInfo = formData;

		fromInfo = {
			...fromInfo,
			userLegend: formData.username === "" ? "Correo electrónico corporativo es un campo obligatorio" : formData.userLegend,
			userState: formData.username === "" ? "error" : formData.userState,
			userIcon: formData.username === "" ? <Error style={{ color: "#b71c1c" }} /> : formData.userIcon,
		};
		setFormData(fromInfo);
		return (fromInfo.userState === "error") ? false : true;
	};

	const initValues = () => {
		setError(null);
		setFormData({
			username: "",
			password: "",
			userState: null,
			enterState: null,
			userLegend: "Entra con el dato que tienes registrado",
			userIcon: null,
			enterLegend: null
		});
	};

	/** Constante que valida el Input de usuario */
	const validateInput = () => {

		const handleValidatorEmail = (objectResponse) => {
			if (objectResponse) {
				setFormData({
					...formData,
					userLegend: "Entra con el dato que tienes registrado",
					userState: "success",
					userIcon: <CheckCircle style={{ color: "#108043" }} />
				});
			}
			else {
				setFormData({
					...formData,
					userLegend: 'Verifica que tu usuario sea correcto',
					userState: "error",
					userIcon: <Error style={{ color: "#b71c1c" }} />
				});
			}
		};

		validateInputEmail({ text: formData.username }, handleValidatorEmail);
	};

	/** Constante que maneja los cambios en los campos */
	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value.trim() });
	};


	/** Codigo de la UI */
	return (
		<LoginLayout image={loginImage} title={"Configura empresas, ajustes y mucho más."}>
			<Grid item component={Paper} className={classes.loginContainer}>
				<div className={classes.logo}>
					<img src={logo} alt="Logo" className={classes.imageIcon} />
				</div>
				<Grid container>
					<div className={classes.title}>
						Portal de Administración
					</div>
				</Grid>
				<div className={classes.paper}>
					<form className={classes.form} onSubmit={handleSubmit} >
						<Grid container spacing={1}>
							<Grid item xs={12}>
								{error ? <LoginAlert severity={error ? error.error ? "error" : "warning" : "error"} error={error} /> : null}
							</Grid>
							<Grid item xs={12}>
								<GTIWebTextField
									name="username"
									type="login"
									disabled={isLoading || isLoadingMFA}
									value={formData.username}
									labelText={"Correo electrónico"}
									placeholder={"Escribe tu correo electrónico corporativo"}
									legendText={formData.userLegend}
									width="100%"
									autoFocus={true}
									icon={formData.userIcon}
									onChange={handleChange}
									onBlur={validateInput}
									onFocus={validateInput}
									state={formData.userState}
									maxLength={255}
								/>
							</Grid>
							{loginType === loginTypes.NORMAL ? <> <Grid item xs={12}>
								<GTIWebTextField
									name="password"
									disabled={isLoading || isLoadingMFA}
									value={formData.password}
									onChange={handleChange}
									placeholder="Escribe tu contraseña"
									labelText="Contraseña"
									type="password"
									legendText={formData.enterLegend}
									onBlur={validateInput}
									onFocus={validateInput}
									width="100%"
									state={formData.enterState}
									preventAutoComplete={true}
								/>
							</Grid>
								<Grid item className={classes.forgotPassword}>
									<GTIWebLink
										linkText={"¿Olvidaste tu contraseña?"}
										disabled={isLoading || isLoadingMFA}
										variant="body2"
										onClick={redirectForgot}
									/>
								</Grid></> : null}
						</Grid>
						<Grid container justify="center" className={classes.signUp}>
							<GTIWebButton
								onlyIcon={isLoading || isLoadingMFA}
								icon={isLoading || isLoadingMFA ? <CircularProgress size={30} /> : null}
								disabled={isLoading || isLoadingMFA}
								iconPosition={"end"}
								type={"submit"}
								buttonText={loginType === loginTypes.NORMAL ? "Entra a tu cuenta" : "Entra con Single Sing-On"}
							/>
						</Grid>
						<Grid>
							<h2 className={classes.dividerLine}>
								<span className={classes.text}>O</span>
							</h2>
						</Grid>

						<Grid container justify="center" className={classes.signUpAlterative}>
							<GTIWebButton
								disabled={isLoading || isLoadingMFA}
								iconPosition={"end"}
								color={"secondary"}
								buttonText={loginType === loginTypes.NORMAL ? "Inicia sesión con SSO" : "Inicia sesión con cuenta +ORDEN"}
								onClick={() => { initValues(); setLoginType(loginType === loginTypes.NORMAL ? loginTypes.SSO : loginTypes.NORMAL) }}
							/>
						</Grid>
					</form>
				</div>
			</Grid>
		</LoginLayout>
	)
};

/** Exportacion del componente para su invocacion en otros archivos */
export default Login;